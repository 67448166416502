<template>
  <div class="buyer">
    <div class="mainbody">
      <div class="header-title">
        <p>买家入驻-基本信息</p>
      </div>
      <div class="header-text">
        <p>
          因合规交易的特殊性，买家必须为企业，请提供企业相关的营业执照等信息进行入驻申请。
        </p>
      </div>
      <div class="basic-information">
        <div class="enterprise-left">
          <div class="enterprise-information">
            <div class="information-title">
              <span>企业信息</span>
            </div>
            <p>
              <span style="color: red">*</span>主体名称:&nbsp;
              <a-input class="buyer-input" v-model="enterprise.commercialName" placeholder="主体名称"
                :class="enterprise.commercialName != '' ? 'activate-input' : ''" />
            </p>
            <p>
              统一信用代码:&nbsp;
              <a-input class="buyer-input" v-model="enterprise.uniformCode" placeholder="统一信用代码"
                :class="enterprise.uniformCode != '' ? 'activate-input' : ''" />
            </p>
            <p>
              <span style="color: red">*</span>法人姓名:&nbsp;
              <a-input class="buyer-input" v-model="enterprise.legalName" placeholder="法人姓名" :maxLength="15"
                :class="enterprise.legalName != '' ? 'activate-input' : ''" />
            </p>
            <p>
              <span style="color: red">*</span>签署邮箱:&nbsp;
              <a-input class="buyer-input" v-model="enterprise.signerEmail" placeholder="用于接收与管理电子合同的邮箱"
                :class="enterprise.signerEmail != '' ? 'activate-input' : ''" />
            </p>
            <!-- <p>
              归属集团简称:&nbsp;
              <a-input
                class="buyer-input"
                v-model="enterprise.abbreviation"
                placeholder="归属集团简称"
                :class="enterprise.abbreviation != '' ? 'activate-input' : ''"
                disabled
              />
            </p> -->
          </div>
        </div>
        <div class="enterprise-right">
          <a-spin class="spin" tip="识别中..." :spinning="spinning">
            <div class="permit-logo" @click="uploadocr">
              <div class="ocr">
                <input v-show="false" id="addImage" ref="addImage" alt="" class="upload" type="file" name="input"
                  multiple="multiple" accept="image/png,image/jpeg,image/gif,image/jpg" @change="getImgFile" />
                <p><img src="../../assets/images/u161.png" alt="" /></p>
                <p class="ocr-text">请上传企业营业执照*</p>
              </div>
              <div class="ocrimg" v-show="fileName">
                <img :src="fileName" alt="" />
              </div>
            </div>
          </a-spin>
        </div>
      </div>
      <div class="contract-information">
        <div align="center" class="er-qr">
          <img src="@/assets/images/wx-code.png" alt="">
          <p>扫码咨询大客户经理</p>
        </div>
        <div class="information-title">
          <span>合同签署人信息</span>
        </div>
        <div class="upload-content">
          <!-- <div class="upload-msg"><span class="red">*</span>上传的身份证照片需横向、四角完整并尽量满屏（单张照片不超过3M）</div> -->
          <a-space :size="60">
            <a-spin :spinning="idCardLoading && uploadLabel === 'idCardFrontUrl'">
              <img class="card" :class="signer.idCardFrontUrl && 'hasUrl'"
                :src="signer.idCardFrontUrl || require('@/assets/images/card/front.png')"
                @click="uploadIdCard('idCardFrontUrl')">
              <p class="note">*身份证头像面：横向图片、四角完整并尽量满屏（单张照片不超过3M）</p>
            </a-spin>
            <a-spin :spinning="idCardLoading && uploadLabel === 'idCardBackUrl'">
              <img class="card" :class="signer.idCardBackUrl && 'hasUrl'"
                :src="signer.idCardBackUrl || require('@/assets/images/card/back.png')"
                @click="uploadIdCard('idCardBackUrl')">
              <p class="note">*身份证国徽面：横向图片、四角完整并尽量满屏（单张照片不超过3M）</p>
            </a-spin>
          </a-space>
        </div>
        <div class="l-flex-between" style="padding: 0 10px;">
          <div class="enterprise-left" style="padding-top:42px">
            <p>
              <span style="color: red">*</span>姓名:&nbsp;
              <a-input class="buyer-input" v-model="signer.signerName"
                @input="signer.signerName=$event.target.value.replace(/[^\u4E00-\u9FA5]/g,'')" placeholder="签署人姓名"
                :maxLength="15" :class="signer.signerName != '' ? 'activate-input' : ''" />
            </p>
            <p>
              <span style="color: red">*</span>身份证号:&nbsp;
              <a-input class="buyer-input" :maxLength="18"
                @input="signer.signerIdCard=$event.target.value.replace(/^\s+|\s+$/g,'')" v-model="signer.signerIdCard"
                placeholder="签署人身份证号" :class="signer.signerIdCard != '' ? 'activate-input' : ''" />
            </p>
            <p>
              <span style="color: red">*</span>手机号:&nbsp;
              <a-input class="buyer-input" v-model="signer.signerMobile" :maxLength="11"
                @input="signer.signerMobile=$event.target.value.replace(/[^\d]/g,'')" placeholder="签署人手机号"
                :class="signer.signerMobile != '' ? 'activate-input' : ''" />
            </p>
          </div>
          <div class="enterprise-right" style="min-width: 575px;">&nbsp;</div>
        </div>
      </div>
      <div class="xy">
        <a-button class="next" @click="next" :loading="loading"> 下一步</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ocr, apply } from '../../api/buyer'
import { uploadPhoto } from '../../api/utils'
var myreg = /^1[3-9][0-9]{9}$/
const regEmail = /.{1,}@.{1,}\..{1,}/
const idCard = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
import cropperModel from '@/views/application/component/cropperModel.vue'
import { cardOcr, cardBackOcr } from '@/api/seller'

export default {
  components: {},
  data() {
    return {
      enterprise: {
        commercialName: '', //商业名称|主体名称
        legalName: '', // 法人姓名
        address: '', //经营地址
        uniformCode: '', //统一信用代码
        signerEmail: '', //签署人邮箱
        abbreviation: '', //集团
        busiLicenseUrl: '', //营业执照文件地址
        licenseRegTime: '' //营业执照过期时间
      },
      signer: {
        idCardBackUrl: null,
        idCardFrontUrl: null,
        signerName: '', //签署人姓名
        signerIdCard: '', //签署人身份证号
        signerMobile: '' //签署人手机号
      },
      fileName: '', //营业执照
      error: {
        commercialName: '请输入主体名称',
        uniformCode: '请输入统一信用代码',
        signerName: '请输入签署人姓名',
        signerIdCard: '请输入正确的签署人身份证号',
        signerMobile: '请输入正确的签署人手机号',
        fileName: '请上传企业营业执照',
        legalName: '请输入法人姓名',
        signerEmail: '请输入正确的签署邮箱'
      },
      loading: false,
      spinning: false,
      uploadLabel: '',
      idCardLoading: false,
    }
  },

  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    getImgFile(e) {
      if (this.uploadLabel === 'idCardBackUrl' || this.uploadLabel === 'idCardFrontUrl') {
          const fileInfo = {
              uid: '-1',
              name: e.target.files[0].name,
              status: 'done'
          }
          if (e.target.files[0].type.indexOf('image') === -1) {
            this.$message.error('请上传正确的格式')
            e.target.value = null
            return
          }
          if ((e.target.files[0].size / 1024 / 1024) > 3) {
            this.$message.error('图片大小超过3MB，请进行图片压缩处理再上传识别')
            e.target.value = null
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(Array.from(e.target.files)[0])
          const that = this
          fr.onload = e => {
              this.$dialog(cropperModel,
                  {
                  record: e.target.result,
                  fileInfo,
                  isFront: this.uploadLabel === 'idCardFrontUrl',
                  on: {
                      ok (e) {
                      console.log('ok:', e)
                      that.idCardLoading = true
                      if (that.uploadLabel === 'idCardFrontUrl') {
                          cardOcr(e).then(res => {
                          that.idCardLoading = false
                          if (res.code !== 200) {
                              that.signer[that.uploadLabel] = null
                              that.$message.error(res.message)
                              return
                          }
                          that.signer[that.uploadLabel] = e
                          console.log(res.data);
                          that.signer.signerName = res.data.name
                          that.signer.signerIdCard = res.data.idNumber
                          that.uploadLabel = ''
                          }).catch(() => that.idCardLoading = false)
                      }
                      if (that.uploadLabel === 'idCardBackUrl') {
                          cardBackOcr(e).then(res => {
                          that.idCardLoading = false
                          if (res.code !== 200) {
                              that.signer[that.uploadLabel] = null
                              that.$message.error(res.message)
                              return
                          }
                          that.signer[that.uploadLabel] = e
                          that.uploadLabel = ''
                          console.log(res.data);
                          }).catch(() => that.idCardLoading = false)
                      }
                      },
                      cancel () {
                      console.log('cancel:')
                      // that.$refs._component.$emit('close')
                      },
                      close () {
                      console.log('close')
                      }
                  }
                  },
                  {
                  title: '上传身份证信息',
                  width: 715,
                  centered: true,
                  footer: ''
              })
          }
          e.target.value = null
        return
      }
      if (e.target.files[0]) {
        if (e.target.files[0].type.indexOf('image') === -1) {
          this.$message.error('请上传正确的格式')
          e.target.value = null
          return
        }
        if ((e.target.files[0].size / 1024 / 1024) > 1.5) {
          this.$message.error('图片大小超过1.5MB，请进行图片压缩处理再上传识别')
          e.target.value = null
          return
        }
        this.uploadImage(e.target.files)
        e.target.value = null
      }
    },
    // 选择图片
    uploadocr() {
      this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
    },
    // 上传图片
    async uploadImage(images) {
      const file = images[0]
      uploadPhoto(file, (info) => {
        this.spinning = true
        ocr({ url: info }).then(async (res) => {
          this.spinning = false
          if (res.code === 200) {
            this.fileName = await info
            this.enterprise.uniformCode = res.data.uniformCode
            this.enterprise.commercialName = res.data.commercialName
            this.enterprise.legalName = res.data.legalName
            this.enterprise.address = res.data.address
            this.enterprise.licenseRegTime = res.data.licenseRegTime
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 点击提交
    next() {
      let verify = false
      if (!this.fileName) {
        this.$message.error(this.error.fileName)
      } else if (!this.enterprise.commercialName) {
        this.$message.error(this.error.commercialName)
      } else if (!this.enterprise.uniformCode) {
        this.$message.error(this.error.uniformCode)
      } else if (!this.enterprise.legalName) {
        this.$message.error(this.error.legalName)
      } else if (!regEmail.test(this.enterprise.signerEmail)) {
        this.$message.error(this.error.signerEmail)
      } else if (!this.signer.signerName) {
        this.$message.error(this.error.signerName)
      } else if (!idCard.test(this.signer.signerIdCard)) {
        this.$message.error(this.error.signerIdCard)
      } else if (!myreg.test(this.signer.signerMobile)) {
        this.$message.error(this.error.signerMobile)
      } else {
        verify = true
        this.loading = true
      }
      if (verify)
        return apply({
          legalName: this.enterprise.legalName,
          address: this.enterprise.address,
          commercialName: this.enterprise.commercialName,
          uniformCode: this.enterprise.uniformCode,
          busiLicenseUrl: this.fileName,
          email: this.enterprise.signerEmail,
          licenseRegTime: this.enterprise.licenseRegTime,
          ...this.signer
        }).then((res) => {
          this.loading = false
          if (res.code == 200) {
            this.$store.dispatch("getuserMenu")
            this.$router.replace({
              path: '/bankInformation',
              query: {
                entityId: res.data.entityId,
                commercialName: this.enterprise.commercialName
              }
            })
          } else {
            this.$message.error(res.message)
          }
        }).catch(r => {
          this.loading = false
          this.$message.error(r.message)
        })
    },
    uploadIdCard(e) {
      if (this.idCardLoading) {
        return
      }
      this.uploadLabel = e
      this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
    },

  }
}
</script>

<style lang="less" scoped>
.buyer {
  margin-bottom: 100px;
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px) {
    padding: 0 15px;
  }
  margin: 20px auto;
  .mainbody {
    padding-bottom: 42px;
    background-color: #ffffff;
    .header-title {
      padding: 30px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-weight: 650;
    }
    .header-text {
      padding: 0 0 0 46px;
      font-size: 16px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.647058823529412);
    }
    .contract-information {
      padding: 20px 20px 60px 20px;
      position: relative;
      .er-qr {
        @media screen and (max-width: 1140px) {
          top: 370px;
          right: 75px;
        }
        position: absolute;
        top: 64px;
        right: 55px;
        font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
        font-weight: 700;
        font-style: normal;
        color: #f59a23;
        img {
          width: 117px;
          height: 117px;
        }
      }
    }
    .enterprise-left {
      font-size: 14px;
      font-weight: 400;
      width: 470px;
      padding: 60px 0 0 0;
      text-align: right;
      .buyer-input {
        width: 343px;
        @media screen and (max-width: 1200px) {
          width: 220px;
        }
        height: 32px;
        border: 1px solid rgba(0, 0, 0, 0.247058823529412);
        // background-color: rgba(245, 245, 245, 1);
        border-radius: 5px;
        padding: 0 0 0 10px;
      }
      .activate-input {
        background-color: #ffffff;
      }
      // .enterprise-information {
      // }
      p {
        margin-bottom: 20px;
      }
    }
    .information-title {
      text-align: left;
      font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
      padding: 0 0 20px 45px;
      font-size: 18px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.847058823529412);
    }
    .enterprise-right {
      width: 630px;
      position: relative;
      @media screen and (max-width: 1200px) {
        transform: scale(0.8);
      }
      padding: 65px 0 0 25px;
      .permit-logo {
        cursor: pointer;
        width: 550px;
        height: 230px;
        border: 1px dashed transparent;
        background: linear-gradient(white, white) padding-box,
          repeating-linear-gradient(
            -45deg,
            #1890ff 0,
            #ccc 0.3em,
            white 0,
            white 0.7em
          );
        display: flex;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        .ocr {
          width: 240px;
          height: 230px;
          text-align: center;
        }
        p {
          margin: 50px auto;
          width: 63px;
          height: 51px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .ocr-text {
          font-size: 14px;
          width: 240px;
        }
        .ocrimg {
          width: 240px;
          height: 230px;
          position: absolute;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .basic-information {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
    }
    .xy {
      width: 137px;
      margin: auto;
    }
    .next {
      width: 137px;
      height: 40px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      background-color: rgba(24, 144, 255, 1);
      line-height: 40px;
      text-align: center;
      margin: 0 auto;
      color: #ffffff;
    }
  }
}
/deep/.ant-spin-dot-item {
  background-color: rgba(24, 144, 255, 1);
}
.spin /deep/.ant-spin {
  font-size: 20px;
  font-weight: 500;
  color: #1890ff;
  margin-left: -25px;
}

.upload-content {
  margin-top: 12px;
  padding-left: 130px;
  @media screen and (max-width: 1024px) {
    padding-left: 114px;
  }
  .upload-msg {
    width: 762px;
    margin: 0 auto 25px;
  }
  .upload {
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    border: 1px dashed #1890ff;
    cursor: pointer;
    display: inline-block;
    width: 365px;
    height: 205px;
    font-weight: 700;
    color: #1890ff;
    font-size: 40px;
    text-align: center;
    line-height: 205px;
  }
  .card {
    cursor: pointer;
    width: 349px;
    height: 214px;
  }
  .hasUrl {
    border-radius: 19px;
    border: 2px solid #d0dcef;
    object-fit: contain;
  }
  .note {
    font-size: 16px;
    color: #d9001b;
    line-height: 24px;
    width: 349px;
  }
}
</style>