<template>
  <div>
    <a-row type="flex">
      <a-col :span="24" style="margin-bottom: 24px">
        <a-upload :file-list="fileList" :before-upload="beforeUpload" accept="image/*">
          <a-button> <a-icon type="upload" />选择图片</a-button>
        </a-upload>
      </a-col>
      <a-col :style="{ height: '340px', width: '376px' }">
        <VueCropper
          v-if="options.img"
          ref="cropper"
          :img="options.img"
          :info="true"
          outputType="png"
          :autoCrop="options.autoCrop"
          :autoCropWidth="options.autoCropWidth"
          :autoCropHeight="options.autoCropHeight"
          :fixedBox="options.fixedBox"
          full
          @realTime="realTime"
        >
        </VueCropper>
      </a-col>
      <a-col :style="{ height: '340px' }" class="Imag">
        <p class="hint">*请截取四角完整、满屏的图片</p>
        <img :src="previewsBase64" class="image-s" />
        <div class="sample">
          <div class="background">
            <img :src="require(`@/assets/images/cropperModel/${isFront ? 'card-f' : 'card-b'}.png`)" alt="">
          </div>
          <div align="center">
            <a-space>
              <a-icon type="check-circle" theme="filled" />
              正确示例
            </a-space>
          </div>
        </div>
      </a-col>
    </a-row>
    <br />
    <a-row>
      <a-col :lg="{ span: 1, offset: 0 }">
        <a-button icon="plus" @click="changeScale(1)" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="minus" @click="changeScale(-1)" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="undo" @click="rotateLeft" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="redo" @click="rotateRight" />
      </a-col>
      <!-- <a-col :lg="{ span: 2, offset: 6 }" :md="2">
        <a-button type="primary" @click="finish()" :loading="loading">保存</a-button>
      </a-col> -->
      <a-col :span="24" class="c-title">错误示例</a-col>
    </a-row>
    <a-row :gutter="20">
      <a-col :span="6" class="col-error" v-for="(item, index) in errorList" :key="index">
        <div class="background">
          <img :class="{0: 'lack', 2: 'rotate'}[index]" :src="item.img" alt="">
        </div>
        <div align="center">
          <a-space>
            <a-icon type="close-circle" theme="filled" />
            {{ item.name }}
          </a-space>
        </div>
      </a-col>

      <a-col :span="24" class="btn" align="center">
        <a-button type="primary" @click="finish()" :loading="loading">保存</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { uploadPhoto } from '@/api/utils'
import { VueCropper } from 'vue-cropper'
export default {
  props: {
    record: {
      type: String,
      default: ''
    },
    fileInfo: {
      type: Object,
      default: () => {}
    },
    isFront: {
      type: Boolean,
      default: true
    }
  },
  components:{
    VueCropper
  },
  data () {
    return {
      previewsBase64: '',
      loading: false,
      options: {
        img: this.record,
        autoCrop: true,
        autoCropWidth: 250,
        autoCropHeight: 160
      },
      fileList: [],
      errorList: [
        { img: require(`@/assets/images/cropperModel/${this.isFront ? 'card-f' : 'card-b'}.png`), name: '边框缺失' },
        { img: require(`@/assets/images/cropperModel/${this.isFront ? 'card-f-dim' : 'card-b-dim'}.png`), name: '照片模糊' },
        { img: require(`@/assets/images/cropperModel/${this.isFront ? 'card-f' : 'card-b'}.png`), name: '照片倾斜' },
        { img: require(`@/assets/images/cropperModel/${this.isFront ? 'card-f-bright' : 'card-b-bright'}.png`), name: '照片过亮/过暗' }
      ]
    }
  },
  mounted () {
    console.log(2222, this.fileInfo)
    this.fileList = [this.fileInfo]
  },
  methods: {
    beforeUpload (file) {
      if (file.type.indexOf('image') === -1) {
        this.$message.error('请上传正确的格式')
        return
      }
      if ((file.size / 1024 / 1024) > 3) {
        this.$message.error('图片大小超过3MB，请进行图片压缩处理再上传识别')
        return
      }
      console.log(file)
      this.fileList = [file]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.options.img = reader.result
      }
      return false
    },
    // bs64转File
    dataURLtoFile (dataurl, fileName) {
      if (!dataurl) return
      dataurl = dataurl.replace(/\n/g, '')
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, { type: mime })
    },
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
      this.$refs.cropper.getCropData((data) => {
        this.previewsBase64 = data
      })
    },
    finish () {
      this.loading = true
      uploadPhoto(this.dataURLtoFile(this.previewsBase64, 'headid.png'), (url) => {
        this.loading = false
        this.$parent.$parent.$parent.$parent.$parent.$parent.handleOk(url)
      })
    },
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    realTime (data) {
      data.w &&
        this.$refs.cropper.getCropData((data) => {
          this.previewsBase64 = data
        })
    }
  }
}
</script>

<style lang="less" scoped>
.Imag {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  padding-left: 70px;
  position: relative;
  .image-s {
    border: 1px dashed #1890ff;
    object-fit: contain;
    width: 220px;
    height: 145px;
  }
  .hint {
    font-family: 'Arial Normal', 'Arial', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #FF1C16;
    margin-bottom: 6px;
  }
  .sample {
    margin-top: 24px;
    font-family: 'Arial Normal', 'Arial', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    .background {
      height: 145px;
      background-color: rgba(242, 242, 242, 1);
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 208px;
      }
    }
    i {
      color: #20b759;
      font-size: 19px;
    }
  }
}
.c-title {
  font-family: 'Arial Normal', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 8px;
}
.col-error {
  margin-bottom: 30px;
  .background {
      height: 100px;
      background-color: rgba(242, 242, 242, 1);
      margin-bottom: 12px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        // width: 127px;
        height: 88px;
      }
      .lack {
        margin-left: 50px;
      }
      .rotate {
        transform: rotate(5deg);
      }
    }
  i {
      color: #ff1c16;
      font-size: 19px;
    }
}
.btn {
  button {
    width: 130px;
  }
}
/deep/ .ant-upload-list-item-card-actions {
  display: none;
}
</style>
